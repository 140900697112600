/* Menu coloring */

ul.rolunk li:nth-child(2) a,
ul.aktualitasok li:nth-child(3) a,
ul.konferenciak li:nth-child(4) a,
ul.galeriak li:nth-child(5) a,
ul.onkentesseg li:nth-child(6) a,
ul.tamogatas li:nth-child(7) a {
    background-color: #000;
    color: #ccc;
    text-decoration: none;
}



/* View sculpture */

.site main section#view-section {
    padding-top: 175px;
    padding-bottom: 20px;
    margin-top: 0;
}
.site main section#view-section > div {
    width: 100%;
}

.btn-back {
    color: #f9e805;
    background-color: #b3b3b3;
    margin-bottom: 0;
    cursor: pointer;
}

.content-view-content.no-back-btn .btn-back {
    display: none;
}

article img {
    max-width: 100%;
}

article table {
    margin: 10px 0;
}

article table td,
article table th {
    padding: 7px 8px;
}
article table tr:first-child {
    background-color: #eee;
}

article table p {
    margin: 0;
}
article h1, article h2, article h3, article h4, article h5, article h6 {
    line-height: 1.2em;
    margin: 0 0 1em 0;
}
article p {
    margin: 0em 0 1em;
}
article .author {
    height: 18px;
}
article .footer {
    font-size: 15px;
    margin: 40px 0 20px;
}
article .footer .label {
    display: inline-block;
    margin: 5px 0 5px 8px;
    padding: 1px 4px;
    background-color: #f9e805;
    border-radius: 5px;
}

/* View elements */

#view-section .social-block {
    top: 0;
}

#view-section .social-block h1 {
    display: none;
}
#view-section .social-block .content-list-elements {
    display: inline-block;
}
#view-section .social-block .content-list-elements a:hover,
#view-section .social-block .content-list-elements a:focus {
    display: block;
    margin: -2px 0 -1px 0;
}

#view-section article {
    position: relative;
    min-height: 200px;
}

#view-section header {
    position: relative;
    padding-left: 10px;
    /*height: 100%;*/
}
#view-section header .article-date {
    display: block;
    line-height: 1.2em;
}

#view-section h1 {
    position: relative;
    font-family: 'Lato', sans-serif;
    font-size: 27px;
    font-weight: 500;
    margin: 0 0 20px;
    padding: 0;
    text-transform: uppercase;
    width: 87%;
    text-align: left;
}
@media (max-width: 950px) {
    #view-section h1 {
    width: 100%;    
    }
}

#view-section h1:after {
    display: block;
    content: "";
    position: absolute;
    left: 2px;
    bottom: -5px;
    width: 114px;
    border-top: 2px solid #000;
}

#view-section .article-content, #view-section .default-contentview {
    display: block;
    position: relative;
    margin: -50px 225px 40px 175px; /* -50px 350px 40px 175px */
    min-height: 300px;
}
#view-section .article-content .article-lead,
#view-section .article-content .article-body {    
    font-size: 17px;
    line-height: 1.2em;
    margin-top: 15px;
}

#view-section .view-image-container {
    display: block;
    float: right;
    width: 333px;
/*    position: absolute;
    top: 0;
    left: 100%; */
    margin-right: -225px;
    margin-left: 20px;
}
#view-section .view-image-container img {
    max-width: 100%;
}




.labels-title {
    display: inline-block;
    width:  50px;
    vertical-align: top;
    margin-top: 5px;
}

.labels-container {
    display: inline-block;
    width:  calc(100% - 60px);
}



#view-section .AttachmentPortlet {
    margin: -20px 0 0 175px;
}

@media (max-width: 1040px) {
    #view-section .AttachmentPortlet {
        margin-left: 33%;
    }
}
@media (max-width: 500px) {
    #view-section .AttachmentPortlet {
        margin-left: 0;
    }
}

#view-section .AttachmentPortlet h2 {
    position: relative;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-weight: 500;
    margin: 0 0 30px;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
}

#view-section .AttachmentPortlet h2:after {
    display: block;
    content: "";
    position: absolute;
    left: 2px;
    bottom: -5px;
    width: 80px;
    border-top: 2px solid #000;
}
#view-section .AttachmentPortlet ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
#view-section .AttachmentPortlet .document-icon img {
    display: block;
    width: 19px;
    margin: 0 8px 0 16px;
}
#view-section .AttachmentPortlet a {
    display: block;
    height: 30px;
    text-decoration: none;
    color: inherit;
}
#view-section .AttachmentPortlet a > span {
    display: inline-block;
    height: auto;
    vertical-align: middle;
}
#view-section .AttachmentPortlet a:hover,
#view-section .AttachmentPortlet a:focus {
    text-decoration: underline;
}






#view-section .view-container img {
    display: block;
    margin: 0 auto;
    width: auto;
    max-width: 100%;
}



/* View lapozók */

#view-news-section .no-elemnts-in-list {
    background-color: #f9e805;
    height: 30px;
}
#view-news-section {
    background-color: #f9e805;
    padding-top: 0;
    margin-top: 0;
}

#view-news-section .background-image-container,
#view-news-section .btn,
#view-news-section h1 {
    display: none;
}





.text-center {
    margin-top: -30px;
}

ul.pagination {
    list-style-type: none;
    margin: 0;
    padding: 0;
/*    position:  absolute;
    width:  100%;
    height:  100%;
    left: 0;
    top: 0;*/
}




.main-view .content-list-container {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0 2%;
}
.main-view {
    position:  relative;
}

ul.pagination li {
    display:  none;
    top: 200px;
    font-size: 0;
    position: absolute;
}
ul.pagination li:last-child {
    display: block;
    right: 40px;
}
ul.pagination li:first-of-type {
    display: block;
    left: 40px;
}
ul.pagination li:last-child a {
    content: "";
    display:  block;
    width: 40px;
    height: 40px;
    background-image: url(../images/kacsacsor.svg);
    background-size: 10px 20px;
    background-repeat: no-repeat;
    background-color: rgba(255,255,255,0.5);
    background-position: center;
}
ul.pagination li:first-of-type a {
    content: "";
    display:  block;
    width: 40px;
    height: 40px;
    background-image: url(../images/kacsacsor-vissza.svg);
    background-size: 10px 20px;
    background-repeat: no-repeat;
    background-color: rgba(255,255,255,0.5);
    background-position: center;
}
ul.pagination li a:hover,
ul.pagination li a:focus {
    background-size: 30%;
    background-color: rgba(255,255,255,0.7);    
    -webkit-box-shadow: 0 0 3px 0 #aaaaaa;    
            box-shadow: 0 0 3px 0 #aaaaaa;
}

.navigation-numbers {
    display: block;
    position: absolute;
    top: 40px;
    left: 40px;
    padding: 5px;
    width: 30px;
    text-align: center;
}



#view-news-section .content-list-elements:hover,
#view-news-section .content-list-elements:focus,
#news-section .content-list-elements:hover,
#news-section .content-list-elements:focus,
#events-section .content-list-elements:hover,
#events-section .content-list-elements:focus,
#funding_purchase-section .content-list-elements:hover,
#funding_purchase-section .content-list-elements:focus {
    -webkit-box-shadow: 0 0 10px 0 #8a8a8a;
            box-shadow: 0 0 10px 0 #8a8a8a;
}


/* Kiemelt támogatók */

#view-section .kiemelt h1.content-list-title {
    padding-left: 10px;
}
#view-section .kiemelt h1:after {
    left: 12px;
}

.kiemelt-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;    
}

.kiemelt-container .content-list-elements {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 33.3%;
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    align-self: center;
}
.kiemelt-container a {
    text-align: center;
    font-size: 13px;
}

.kiemelt-container img {
    display: block;
    max-width: 100%;
    max-height: 120px;
    margin: 0 auto;
}

.kiemelt-container .smaller img {
    padding: 15px;
    box-sizing: border-box;
}



.kiemelt-container .content-list-elements.fotamogato {
    width: 100%;
}
.kiemelt-container .content-list-elements.fotamogato a {
    font-size: 21px;
}
.kiemelt-container .content-list-elements.fotamogato img {
    max-height: 170px;
}
.kiemelt-container .content-list-elements.fotamogato ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.kiemelt-container .content-list-elements.fotamogato .list-title.link {
    margin: 0 auto;
    display: block;
}
.kiemelt-container .content-list-elements.fotamogato .list-lead.link p {
    font-size: 14px;
    margin: 1em 20% 0;
}
    
    

/* KEOP Gallery */

.tamogatas span[data-embed="embedPanel"] {
    font-size: 0;
}

.tamogatas article .gallery-view {
    display: inline-block;
    width: 49%;
    text-align: center;
    font-size: 13px;
    vertical-align: top;
}

.tamogatas article .gallery-view a {
    display: block;
    height: 186px;
    overflow: hidden;
    text-decoration: none;
    background-color: #000;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
}
/*.tamogatas article .gallery-view a .img-thumbnail {
    width: 100%;
    min-height: 100%;
    opacity: 0.9;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block;
    position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}*/
.tamogatas article .gallery-view:hover a,
.tamogatas article .gallery-view:focus a {
    background-color: #f9e805;
    padding: 0;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
}
.tamogatas article .gallery-view a:hover h2,
.tamogatas article .gallery-view a:focus h2 {
    font-size: 15px;
    display: block;
    position: relative;
    text-align: center;
    font-family: Calibri, Sans-Serif;
    color: #000;
    margin: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transform-origin: center;
            transform-origin: center;
    top: 50%;
    padding: 15px 3px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}
.tamogatas article .gallery-view a:hover .img-thumbnail,
.tamogatas article .gallery-view a:focus .img-thumbnail {    
    display: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.keop span.open-title {
    white-space: normal;
    display:  none;
}

#view-section .keop [data-embed="embedPanel"] .gallery-view h1 {
    font-size: 15px;
    display: block;
    position: absolute;
    text-align: center;
    font-family: Calibri, Sans-Serif;
    color: #000;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transform-origin: center;
            transform-origin: center;
    top: 50%;
    padding: 15px 1px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    z-index: -1;
    margin-left: 15%;
    width: 70%;
    text-transform: none;
}
#view-section .keop [data-embed="embedPanel"] .gallery-view:hover h1 {
    color: #000;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform-origin: center;
    transform-origin: center;
    top: 50%;
    padding: 15px 1px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    z-index: 2;
    margin-left: 15%;
    width: 70%;
    text-transform: none;
    cursor: pointer;
}

#view-section .keop [data-embed="embedPanel"] .gallery-view h1:after {
    display:  none;
}



/* Single gallery at view */

.gallery-view {
    text-align: center;
    position: relative;
}
.gallery-view h1 {
    display: none;
}

.galeriak .gallery-view h1 {
    display: block;
}


span.open-title {
    display: block;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 16px;
    color: rgba(255,255,0,0.8);
    background-color: rgba(0,0,0,0.6);
    padding: 5px 10px;
    font-weight: bold;
    white-space: nowrap;
}





#view-news-section .none-listed.content-list-elements {
    display: none;
}

.main-view.konferenciak a[href*="http"]{
    word-break: break-word;
}

